/* Navbar.css */

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px; /* Adjust the height as needed */
    background-color: white; /* Example background color */
  }
  
  .navbar img {
    width: 50px; /* Adjust the width of the logo as needed */
  }
  